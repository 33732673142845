import React from 'react';
import { Grid, Typography } from "@mui/material";

import '../css/landingPage.css'

const About = () => {
    return (
        <Grid item>
            <Typography variant="h4">
                About Me
            </Typography>
            <br/>
            <Typography>
                Heyo! I go by the name Chethan and "crdy" as my pen name. This entire site runs on an old dusty laptop sitting lazily in my house, making the loudest possible noise from its fan, I need to get that thing checked. A bit about me, I am a curious lad always looking for new things to try and learn. Often I encounter things I read and feel the need to create a place to experiment with all those crazy things, and this blog is a product of that.
                <br />
                <br />
                A bit about this site, right now I am running this site completely static but still client-side rendered, using React. My choice for React is because it makes templating very easy, and also fits right in my plans to integrate a backend for the site to dynamically fetch content. I have kept this site pretty basic, but I hope to improve this site slowly over time.
                <br />
                <br />
                I am open to suggestions on anything; Blog, Content, TV Shows, Sports, or Conspiracy Theories (don't even get me started on that). Hit me up on my mail, I'm a pretty consistent mail checker, trust me I don't even miss the spam. See ya!
            </Typography>
        </Grid>
    );
};

export default About;
