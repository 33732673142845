import React from 'react';
import { useParams } from "react-router-dom";
import { Grid, Typography } from "@mui/material";

const BlogPost = () => {
    const { blogPostId } = useParams();
    return (
        <Grid container item spacing={2} direction="column">
            <Grid item>
                <Typography variant="h5"> 
                    Hi There! {blogPostId} 
                </Typography>
            </Grid>
            <Grid item>
                <Typography>
                    This is a test post
                </Typography>
            </Grid>
        </Grid>
    );
};

export default BlogPost;
