import React from 'react';

import { Grid } from "@mui/material";
import AppBar from '@mui/material/AppBar';
import Drawer from '@mui/material/Drawer';
import Toolbar from '@mui/material/Toolbar';
import MenuIcon from '@mui/icons-material/Menu';
import IconButton from '@mui/material/IconButton';

import theme from "./Theme";
import MenuBar from "./MenuBar";

const NavWrapper = ({ Content }) => {
    const [isDrawerOpen, setIsDrawerOpen] = React.useState(false);

    const handleDrawerToggle = () => {
        setIsDrawerOpen((prevState) => !prevState);
    };

    return (
        <Grid
            container
            justifyContent="center">
            <Grid
                item md={12}
                sx={{ display: { md: 'none' } }}>
                <AppBar position='absolute' color="inherit" elevation={0}>
                    <Toolbar sx={{ justifyContent: "flex-end" }}>
                        <IconButton
                            sx={{ color: "#000000" }}
                            aria-label="open drawer"
                            edge="start"
                            onClick={handleDrawerToggle}
                        >
                            <MenuIcon />
                        </IconButton>
                    </Toolbar>
                </AppBar>
                <nav>
                    <Drawer
                        anchor='right'
                        variant="temporary"
                        open={isDrawerOpen}
                        onClose={handleDrawerToggle}
                        ModalProps={{
                            keepMounted: true, // Better open performance on mobile.
                        }}
                        sx={{
                            marginTop: "5%",
                            display: { xs: 'block', sm: 'none' },
                            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: '50%' },
                        }}
                    >
                        <Grid container sx={{ marginTop: "30%" }}>
                            <MenuBar isMobileNav={true} navClickHandler={handleDrawerToggle} />
                        </Grid>
                    </Drawer>
                </nav>
            </Grid>
            <Grid
                item
                container
                spacing={4}
                direction="row"
                sx={{
                    marginTop: "5%",
                    [theme.breakpoints.down('md')]: {
                        width: "90%"
                    },
                    [theme.breakpoints.up('md')]: {
                        width: "60%"
                    }
                }}
            >
                <Grid item xm={12} md={10}>
                    <Content />
                </Grid>
                <MenuBar isMobileNav={false} />
            </Grid>
        </Grid>
    );
};

export default NavWrapper;
