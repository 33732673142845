import { createTheme, responsiveFontSizes } from "@mui/material";

let theme = createTheme({
    typography: {
        fontFamily: 'Montserrat',
        fontSize: 16
    }
});

theme = responsiveFontSizes(theme);

export default theme;