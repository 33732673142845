import React from 'react';
import { Grid, Typography } from "@mui/material";

import { SiGmail } from 'react-icons/si';
import { FiGithub } from 'react-icons/fi';
import { FaLinkedinIn } from 'react-icons/fa';

const LandingPage = () => {
    return (
        <Grid item>
            <Typography variant="h4">
                Hey There!
            </Typography>
            <br/>
            <br/>
            <Typography>
                Welcome to an unexplored corner of the Internet! Here I write (atleast I hope to!) stuff I find interesting and weird.
                If you like what I write bookmark it or just click on the star on your browser, that works too!
            </Typography>
            <br/>
            <Grid 
                container 
                direction="row"
                spacing={1.2}
                alignItems="center">
                <Grid item>
                    <span> &#128073; </span>  
                </Grid>
                <Grid item>
                    <Typography> Hit me up at </Typography>
                </Grid>
                <Grid item>
                    <a href='mailto:crdy2k1@gmail.com'>
                    <SiGmail size={30} />
                    </a>
                </Grid>
            </Grid>
            <br/>
            <Grid 
                container 
                direction="row"
                spacing={1.2}
                alignItems="center">
                <Grid item>
                    <span> &#128270; </span>  
                </Grid>
                <Grid item>
                    <Typography> Find me on </Typography>
                </Grid>
                <Grid item>
                    <a href='https://github.com/Chethan2k1'>
                    <FiGithub size={30} />
                    </a>
                </Grid>
                <Grid item>
                    <a href='https://www.linkedin.com/in/ch-chethan-reddy/'>
                    <FaLinkedinIn size={30} />
                    </a>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default LandingPage;
