import React from 'react';

import { Grid, Typography } from "@mui/material";
import { Link } from "react-router-dom"

import theme from "./Theme";

const MenuBar = ({ isMobileNav, navClickHandler }) => {
    return (
        <Grid
            container
            item
            spacing={2}
            md={2}
            direction="column"
            justifyContent="flex-start"
            alignItems="center"
            sx={{
                ...(!isMobileNav && {
                    [theme.breakpoints.down('md')]: {
                        display: "none"
                    }
                })
            }}>
            <Grid item onClick={navClickHandler}>
                <Link to="/">
                    <Typography variant="h6">
                        Home
                    </Typography>
                </Link>
            </Grid>
            <Grid item onClick={navClickHandler}>
                <Link to="/about">
                    <Typography variant="h6">
                        About
                    </Typography>
                </Link>
            </Grid>
            <Grid item onClick={navClickHandler}>
                <Link to="/blog">
                    <Typography variant="h6">
                        Blog
                    </Typography>
                </Link>
            </Grid>
        </Grid>
    );
}

export default MenuBar;
