import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import theme from './components/Theme';
import Error from './components/Error';
import About from './components/About';
import BlogList from './components/BlogList';
import BlogPost from './components/BlogPost';
import NavWrapper from './components/NavWrapper';
import LandingPage from './components/LandingPage';
import { ThemeProvider } from '@emotion/react';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <Routes>
          <Route path='/' element={<NavWrapper Content={LandingPage} />} />
          <Route path='about' element={<NavWrapper Content={About} />} />
          <Route path='blog' element={<NavWrapper Content={BlogList} />} />
          <Route path="blog/first-post" element={<NavWrapper Content={BlogPost} />} />
          <Route path='*' element={<Error />} />
        </Routes>
      </BrowserRouter>
    </ThemeProvider>
  </React.StrictMode>
);