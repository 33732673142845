import React from 'react';
import { Grid, Typography } from "@mui/material";
import { Link } from "react-router-dom"

const posts = [
    ["23-02-2023", "First Post!", "first-post", "FirstPost.html"],
];

const BlogList = () => {
    return (
        <Grid container item spacing={2} direction="column">
            <Grid item>
                <Typography variant="h4">
                    Posts
                </Typography>
            </Grid>
            {
                posts.map((post) => {
                    return (
                        <Grid container item spacing="1rem" key={post[2]} direction="row">
                            <Grid item>
                                <Typography> {post[0]} </Typography>
                            </Grid>
                            <Grid item>
                                <Link to={"/blog/" + post[2]}>
                                    <Typography> {post[1]} </Typography>
                                </Link>
                            </Grid>
                        </Grid>
                    );
                })
            }
        </Grid>
    );
};

export default BlogList;
