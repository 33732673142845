import React from 'react';
import { Typography } from "@mui/material";


const Error = () => {
    return (
        <div>
            <Typography> Error page! </Typography>
        </div>
    );
};

export default Error;
